declare var $: any;

export function setupHighlights() {
  $(".highlights.owl-carousel").owlCarousel({
    loop: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    items: 1,
    smartSpeed: 750,
    margin: 40,
  });
}
