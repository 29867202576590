declare var $: any;

export function setupAnimations(items: string[]) {
  window.addEventListener("scroll", function () {
    animateIfNeeded(items);
  });
  animateIfNeeded(items);
}

function animateIfNeeded(observingItems: string[]) {
  observingItems.forEach((item) => {
    const elements = document.querySelectorAll<HTMLElement>(item);
    elements.forEach((element) => {
      if (isScrolledIntoView(element)) {
        element.classList.add("visible");
      }
    });
  });
}

function isScrolledIntoView(el: any) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const isVisible = elemTop + rect.height / 4 < window.innerHeight && elemBottom >= 0;
  return isVisible;
}
