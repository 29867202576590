import {setupHighlights} from "layouts/_highlights";
import * as smoothscroll from "smoothscroll-polyfill";
import * as Animations from "./animations";
import "./landing_v2.scss";

declare var $: any;

smoothscroll.polyfill();

document.addEventListener("DOMContentLoaded", () => {
  Animations.setupAnimations(["#corporate", "#fundamental", "#quant", "#custom-queries", "#bookmarking", "#alerts-feature", "#widgets"]);

  setupHighlights();

  /* Banner
  -------------------------------------------------------------------------*/

  const banner = $("#promote-banner");

  banner.find("a.dismiss").click((ev: any) => {
    ev.preventDefault();
    $(document.body).removeClass("banner-active");
  });

  $.getJSON("/banner", (result: any) => {
    if (result.title) {
      banner.find(".title").text(result.title);
      banner.find(".subtitle").text(result.subtitle);

      const mobileSubtitle = " - " + result.subtitle;
      banner.find(".texts-mobile").text(mobileSubtitle);
      const mobileTitle = "<strong>" + result.title + "</strong>";
      banner.find(".texts-mobile").prepend(mobileTitle);

      banner.find("a.open")
        .text(result.action_button_label)
        .attr("href", result.url);

      $(document.body).addClass("banner-visible");

      const theme = result.theme;
      if (theme) {
        $("#promote-banner").css("background-color", `#${theme.bg_color}`);
        $("#promote-banner .title").css("color", `#${theme.title_color}`);
        $("#promote-banner .subtitle").css("color", `#${theme.subtitle_color}`);
        $("#promote-banner .actions .open").css("background-color", `#${theme.action_color}`);
        $("#promote-banner .actions .open").css("color", `#${theme.action_text_color}`);
        $("#promote-banner .actions .dismiss").css("background-color", `#${theme.dismiss_color}`);
        $("#promote-banner .actions .dismiss").css("color", `#${theme.dismiss_text_color}`);
      }

      setTimeout(() => {
        $(document.body).addClass("banner-active");
      }, 500);
    }
  });
});
